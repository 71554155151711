import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { StoreService } from 'src/app/shared/services/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-store-product-list',
  templateUrl: './single-store-product-list.component.html',
  styleUrls: ['./single-store-product-list.component.scss'],
})
export class SingleStoreProductListComponent implements OnInit {
  constructor(
    public store: StoreService,
    public snackBar: SnackBarService,
    private route: ActivatedRoute
  ) {
    this.marketplaceUrl = environment.MARKETPLACE_URL;
  }

  singleStore!: {
    storename: string;
  };

  storeNotFound!: boolean;
  deactivated: boolean = false;

  // productSearch: boolean = false

  storeCode: string = '';
  storeResponse: any;
  type: any;
  selectedCategory: any;
  filter: any;
  productSearch!: boolean;
  query: string = '';
  marketplaceUrl!: string;

  currentStore: any;

  showAddButton: boolean = false;
  storeName: string = '';

  productFetched!: boolean;
  storeFetched: boolean = false;
  productSearchFetched!: boolean;
  currentPage: number = 1;
  available: boolean[] = [];
  totalElements: number = 0;
  totalItems: number = 0;
  lastpage!: number;

  products: any[] = [];

  options: string[] = [
    'All categories',
    'High to Low price',
    'Low to High price',
  ];

  showCartQunatity: any[] = [];
  cart: any[] = JSON.parse(
    localStorage.getItem(this.store.getStoreCartKey('cart'))!
  );

  count: number[] = Array.from(this.products).fill(1);
  @ViewChildren('myImage') images!: QueryList<any>;
  @ViewChildren('containerImage') divs!: QueryList<any>;
  newBackgroundColor: {}[] = [];

  addToCart(code: string, index: number) {
    this.showCartQunatity.forEach((show, i) => {
      if (show.code === code) {
        show.visible = !show.visible;
        const cartProduct = this.products.find(
          (prod: any) => prod.product.product_code === code
        );
        this.store.addCartItem(cartProduct);
      }
    });
  }

  filterAndSearchProduct($event?: any) {
    if ($event) {
      this.query = $event.target.value;
      this.store.currentPage = 1;
    }

    this.productFetched = false;
    this.store
      .productSearchAndFilter(
        this.query,
        this.storeCode,
        this.store.currentPage
      )
      .subscribe({
        next: (response: any) => {
          const res = response.data;

          if (res.length === 0) {
            this.getAllProducts();
            this.snackBar.openSnackBar(`No product found`, 'error-snackbar');
          } else {
            this.products = res;

            this.productSearch = true;
            this.productFetched = true;
            this.store.currentPage = 1;
            this.totalElements = response.totalElements;
            this.lastpage = response.totalPages;
            this.totalItems = this.totalElements * this.lastpage;
          }
        },
        error: (err) => {
          this.snackBar.openSnackBar(`${err.error.message}`, 'error-snackbar');
          this.productFetched = true;
        },
      });
  }

  reset() {
    this.getAllProducts();
  }

  openFilter() {
    this.filter = !this.filter;
  }

  filterWithPrice($event?: any) {
    this.productFetched = false;
    if ($event) {
      this.store.currentPage = 1;
      const value = $event.target.value;
      if (value === 'High to Low price') {
        this.type = 'desc';
      } else if (value === 'Low to High price') {
        this.type = 'asc';
      } else if (value === 'All categories') {
        this.getAllProducts();
        return;
      }
    } else {
      console.log(this.type, this.store.currentPage);
    }

    this.store
      .productFilterByPrice(this.type, this.store.currentPage)
      .subscribe({
        next: (response: any) => {
          const res = response.data;
          this.products = res;
          this.productFetched = true;

          this.totalElements = response.totalElements;
          this.lastpage = response.totalPages;
          this.totalItems = this.totalElements * this.lastpage;
        },
        error: (err) => {
          this.snackBar.openSnackBar(`${err.error.message}`, 'error-snackbar');
        },
      });
  }

  minus(index: number, zer0?: boolean) {
    if (this.count[index] === 1) {
      this.showCartQunatity.forEach((show, i) => {
        if (i === index) {
          this.showCartQunatity[i] = !this.showCartQunatity[i];
        }
      });
      const cartProduct = this.products[index];
      this.store.subtractCartItem(cartProduct);
      return;
    } else {
      this.count[index]--;
      const cartProduct = this.products[index];
      this.store.subtractCartItem(cartProduct);
    }
  }

  plus(i: number) {
    this.products[i].cartQuantity++;
    const cartProduct = this.products[i];
    this.store.addCartItem(cartProduct);
  }

  checkCart(id: string, index: number) {
    const cartProduct = this.products[index];
    this.store.addCartItem(cartProduct);
    const existingCartItem = this.cart.find((item: any) => item.id === id);
    if (existingCartItem) {
      return;
    }
  }

  inventory(product_code: string, index: number) {
    this.available.forEach((show, i) => {
      if (i === index) {
        this.store.checkInventory(product_code).subscribe({
          next: (response: any) => {
            if (response.data === 'In stock') {
              this.available[i] = true;
            } else {
              this.available[i] = false;
            }
          },
          error: (err: any) => {},
        });
      }
    });
  }

  getAllProducts() {
    this.productFetched = false;
    this.store
      .getProductOnClient(this.storeCode, this.store.currentPage)
      .subscribe({
        next: (response: any) => {
          this.products = response.data;
          this.productSearch = false;
          for (const product of this.products) {
            this.showCartQunatity.push({
              code: product.product.product_code,
              visible: false,
            });
          }

          this.totalElements = response.totalElements;
          this.lastpage = response.totalPages;
          this.totalItems = this.totalElements * this.lastpage;
          this.productFetched = true;
        },
        error: (err) => {
          this.productFetched = true;
          this.snackBar.openSnackBar(`Unable to complete`, 'error-snackbar');
          this.productFetched = true;
        },
      });
  }

  getStore(name: string) {
    this.store.findStoreByName(name).subscribe({
      next: (response: any) => {
        this.storeResponse = response;

        if (!this.storeResponse.active) {
          this.deactivated = true;
          this.storeFetched = true;
          this.productFetched = true;
          this.storeNotFound = false;
          return;
        }
        this.storeFetched = true;

        this.storeCode = this.storeResponse.store_code;
        this.currentStore = {
          store_code: this.storeResponse.store_code,
          store_name: this.storeResponse.slug,
        };

        this.store.checkStoreCart(this.storeResponse.store_code);

        sessionStorage.setItem(
          'currentStore',
          JSON.stringify(this.currentStore)
        );
        this.productFetched = false;

        this.getAllProducts();
      },
      error: (err) => {
        this.storeNotFound = true;
        this.storeFetched = true;
        this.productFetched = true;
      },
    });
  }

  changePage(pageNumber: any) {
    this.store.currentPage = pageNumber;
    if (this.type === 'asc' || this.type === 'desc') {
      this.filterWithPrice();
    } else if (this.query) {
      this.filterAndSearchProduct();
    } else {
      this.getAllProducts();
    }

    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.storeFetched = false;
    this.singleStore = {
      storename: this.route.snapshot.params['name'],
    };

    const name = this.singleStore.storename;
    const currentStore: any =
      JSON.parse(sessionStorage.getItem('currentStore')!) ?? null;

    if (
      currentStore &&
      (currentStore?.store_name).toLowerCase() !== name.toLowerCase()
    ) {
      this.store.cartItems = [];
      localStorage.removeItem(this.store.getStoreCartKey('cart'));
      localStorage.removeItem(this.store.getStoreCartKey('cartTotal'));
      this.getStore(name);
    } else {
      this.getStore(name);
    }

    window.scrollTo(0, 0);
  }
}
