export const environment = {
  production: false,
  BASE_URL: 'https://api.dev.gateway.zestpayment.com',
  PRODUCT_URL: 'https://store.dev.gateway.zestpayment.com/stores',
  SETUP_STORE_URL: 'https://business.dev.zestpayment.com',
  MARKETPLACE_URL: 'https://staging.zestpayment.com/marketplace',
  SDK_URL:
    'https://sdk.dev.gateway.zestpayment.com/paymentgatewaysdk/omni-payment-gateway-sdk.js',
  KLUMP_API:
    'klp_pk_test_e4214c364ce545739f1ca136b5e620934365c8c4d1384c7a845e474b70d952e3',
};
