<!-- LOADERS START for store and products -->
<app-loading-spinner *ngIf="!storeFetched"></app-loading-spinner>
<app-loading-spinner *ngIf="!productFetched"></app-loading-spinner>
<!-- LOADERS END -->

<!-- PRODUCT LIST COMPONENT START -->
<section
  appScrollToTop
  *ngIf="products && productFetched && !storeNotFound"
  class="bg-[#FBFBFB] font-mont"
>
  <!-- HEADER COMPONENT -->
  <app-single-store-header
    *ngIf="!deactivated"
    [storeName]="storeResponse"
    (filterWithPrice)="filterWithPrice($event)"
    (searchTermChanged)="filterAndSearchProduct($event)"
  ></app-single-store-header>
  <main
    class="px-1 mx-0 my-8 rounded lg:mx-20 mt-[6rem]"
    *ngIf="products && products.length >= 1; else noProducts"
  >
    <div>

      <div class="py-6 block lg:flex justify-between items-center">
        <div
          *ngIf="productSearch"
          class=" flex items-center px-0 pb-3 lg:pb-0"
        >
          <img
            src="../../../assets/svg/back.svg"
            alt="back"
            class="cursor-pointer"
            (click)="reset()"
          />
          <p class="hidden lg:block font-medium text-zestBlack text-lg pl-4">
            Back
          </p>
        </div>

        <!-- start -->
        <div class="md:hidden flex w-full justify-end">
          <div class="relative w-3/4 xl:w-fit md:mr-2 xl:mr-0">
            <input
              type="text"
              placeholder="Search by product name"
              (change)="filterAndSearchProduct($event)"
              class="w-full text-sm sm:pr-5 mr-3 focus:border-zestGreen focus:ring-zestGreen sm:pl-4 border-[#C1C7D0] rounded py-3 xl:px-4 font-mont lg:text-base xl:w-[384px]"
            />
            <img
              src="../../../assets/svg/Search.svg"
              alt="search"
              class="absolute w-4 h-4 right-2 xl:right-5 bottom-4 mr-1 xl:mr-4"
            />
          </div>
          <select
            name="selectedOption"
            (change)="filterWithPrice($event)"
            class="hidden md:block bg-white w-1/6 md:w-1/4 xl:w-1/6 outline-hidden rounded border text-sm lg:text-base placeholder-zestPlaceholder border-solid border-[#c1c7d0] px-4 py-3 focus:border-zestGreen focus:ring-zestGreen"
          >
            <option *ngFor="let option of options">{{ option }}</option>
          </select>
          <div class="block md:hidden w-1/4 ml-2">
            <button
              (click)="openFilter()"
              class="border border-zestPlaceholder relative text-sm lg:text-base text-zestGrey rounded py-3 px-4"
            >
              <div class=" ">
                <div class="flex justify-center items-center">
                  <p class="pr-2">Filter</p>
                  <img
                    src="../../../assets/svg/equalizerline.svg"
                    alt="equalizer"
                  />
                </div>
              </div>
            </button>
            <div
              class="z-10 absolute bg-white rounded border border-zestNeutral w-1.5/6 right-6 mt-5 p-4 shadow transition-all"
              *ngIf="filter"
            >
              <div class="">
                <select
                  name="selectedOption"
                  (change)="filterWithPrice($event)"
                  class="bg-white text-sm w-full outline-hidden rounded border placeholder-zestPlaceholder border-solid border-[#c1c7d0] px-4 py-3 focus:border-zestGreen focus:ring-zestGreen"
                >
                  <option *ngFor="let option of options">{{ option }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 xl:grid-cols-4 gap-6">
        <div
          *ngFor="
            let product of products
              | paginate : {
                    itemsPerPage: totalElements,
                    currentPage: store.currentPage,
                    totalItems: totalItems
                  };
            let i = index
          "
          class="flex flex-col h-full"
        >
          <div
            class="mr-0 px-0 md:mr-4 md:last:mr-0 xl:px-0 grow flex flex-col"
          >
            <!--? There is custom css as a quick fix here because of issues with tailwindcss media queries  -->
            <div
              class="relative cursor-pointer rounded border overflow-hidden md:h-[355px] w-full flex justify-center items-center"
            >
              <img
                [routerLink]="[
                  '/stores',
                  singleStore.storename | lowercase,
                  product.product.product_code
                ]"
                src="{{ product?.product.image_data[0]?.image_source }}"
                alt="product"
                class="rounded-sm h-[124px] md:h-full p-2 object-contain overflow-hidden"
              />
              <p
                *ngIf="product.product.quantity_in_stock === 0"
                class="absolute bg-[#FEF3F2] text-[#D92D20] top-0 left-2 lg:top-5 lg:left-3 p-1 mt-3 rounded text-xs lg:text-sm font-medium"
              >
                Out of stock
              </p>
            </div>
            <!--? Custom css ends here -->
            <p class="pt-3 pb-4 text-zestLabel font-medium text-sm grow">
              {{ product?.product.product_name }}
            </p>
            <p
              *ngIf="product?.product.discount"
              class="pb-2 text-zestBlack text-base font-medium"
            >
              {{
                product?.product.discount
                ? (product?.product.discounted_price | currency : "₦ ")
                : (product?.product.price | currency : "₦ ")
              }}
            </p>
            <p
              *ngIf="!product?.product.discount"
              class="text-base font-medium text-zestBlack pb-9"
            >
              {{ product?.product.price | currency : "₦ " }}
            </p>
            <div
              class="flex items-center"
              *ngIf="product?.product.discount"
            >
              <p class="line-through text-sm font-medium text-zestLabel">
                {{ product?.product.price | currency : "₦ " }}
              </p>
              <span
                class="inline-block bg-zestGreen text-sm text-zestWhite font-medium p-1 ml-2 rounded"
                >-{{ product?.product.discount }} %</span
              >
            </div>

            <div
              class="flex justify-between items-center w-full mx-auto"
              *ngIf="
                store.getItemCount(product?.product.product_code);
                else showCartButton
              "
            >
              <button
                *ngIf="product?.product.productVariant; else subvariant"
                [routerLink]="[
                  '/stores',
                  singleStore.storename | lowercase,
                  product.product.product_code
                ]"
                class="bg-zestBlack text-white w-12 text-lg h-12 rounded-lg py-2 px-[15px] mt-3 mb-8"
              >
                -
              </button>
              <ng-template #subvariant>
                <button
                  class="bg-zestBlack text-white w-12 text-lg h-12 rounded-lg py-2 px-[15px] mt-3 mb-8"
                  (click)="store.subtractCartItem(product)"
                >
                  -
                </button>
              </ng-template>
              <p class="px-[14px] pb-4 text-base" *ngIf="cart !== null">
                {{ store.getItemCount(product?.product.product_code) }}
              </p>
              <button
                *ngIf="product.product.productVariant; else plusvariant"
                [routerLink]="[
                  '/stores',
                  singleStore.storename | lowercase,
                  product?.product.product_code
                ]"
                class="bg-zestBlack text-white w-12 text-lg h-12 rounded-lg py-2 px-[15px] mt-3 mb-8"
              >
                +
              </button>
              <ng-template #plusvariant>
                <button
                  (click)="store.addCartItem(product)"
                  class="bg-zestBlack text-white w-12 text-lg h-12 rounded-lg py-2 px-[15px] mt-3 mb-8"
                >
                  +
                </button>
              </ng-template>
            </div>

            <ng-template #showCartButton>
              <button
                *ngIf="product?.product.productVariant; else variant"
                [routerLink]="[
                  '/stores',
                  singleStore.storename | lowercase,
                  product?.product.product_code
                ]"
                class="w-full text-sm px-3 md:text-base rounded py-2 md:px-6 mt-3 mb-8 bg-zestWhite h-12 border-[1.2px] border-zestBlack hover:border-none hover:bg-zestBlack text-zestBlack font-medium hover:text-zestBg"
              >
                Add to cart
              </button>
              <ng-template #variant>
                <button
                  (click)="addToCart(product?.product.product_code, i)"
                  class="w-full text-sm px-3 md:text-base rounded py-2 md:px-6 mt-3 mb-8 bg-zestWhite h-12 border-[1.2px] border-zestBlack hover:border-none hover:bg-zestBlack text-zestBlack font-medium hover:text-zestBg"
                >
                  Add to cart
                </button>
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <p>Showing page {{ store.currentPage }} of {{ lastpage }}</p>
        <pagination-controls
          class="custom-pagination items-center mt-2"
          previousLabel="Prev"
          (pageChange)="changePage($event)"
        ></pagination-controls>
      </div>
    </div>
    <!-- <div class="hidden lg:justify-between lg:items-center lg:pb-6">
      <p class="text-zestLabel">Showing page 1 of 5</p>
      <div class="flex justify-between items-center">
        <p
          class="text-zestLabel underline underline-offset-4 pr-2 cursor-pointer"
        >
          Previous Page
        </p>
        <p
          class="cursor-pointer bg-[#F4F6F9] rounded flex justify-center px-2 mx-4"
        >
          <span class="inline-block">1</span>
        </p>
        <p class="cursor-pointer mr-6">2</p>
        <p class="cursor-pointer">3</p>
        <p class="px-[19px] pb-2">...</p>
        <p class="cursor-pointer mr-4">5</p>
        <p
          class="text-zestBlack underline underline-offset-4 mr-4 cursor-pointer"
        >
          Next Page
        </p>
      </div>
    </div> -->
  </main>
  <ng-template #noProducts>
    <main class="sm:mx-0 bg-white my-8 rounded lg:mx-[152px] px-6" *ngIf="storeResponse.active; else inactive">
      <div class="w-full lg:w-1/3 mx-auto py-[130px] text-center">
        <div class="">
          <img
            class="w-14 h-14 mx-auto"
            src="../../../assets/svg/productAdd.svg"
            alt="storeicon"
          />
        </div>
        <p class="font-semibold text-zestBlack py-[9px]">No product added</p>
        <div class="text-zestLabel text-sm tracking-wide mb-8">
          <p>No product has been added to this store yet.</p>
        </div>
      </div>
    </main>
    <ng-template #inactive>
      <main *ngIf="deactivated">
        <div
          class="flex flex-col place-items-center place-content-center font-mont h-screen my-8 lg:my-0"
        >
        <div>
          <img src="../../assets/svg/deactivate.svg" alt="deactivate" />
        </div>
          <p class="text-center pt-4 pb-2 text-zestBlack font-medium text-xl">
            404 Error
          </p>
          <p class="text-base font-medium leading-tight text-zestTertiary-500 text-center">No results found.</p>
          <button class="mt-6 bg-zestBlack text-white py-3 px-4 rounded-lg"><a target="_blank" rel="noreferrer noopener" [href]="marketplaceUrl">Explore more products</a></button>
        </div>

      </main>
    </ng-template>
  </ng-template>
  <!-- REVIEW COMPONENT -->
  <section class="sm:mx-5 px-5 lg:mx-[154px]">
    <app-single-store-reviews
      [rating]="storeResponse"
    ></app-single-store-reviews>
  </section>
  <!-- FOOTER COMPONENT -->
  <section class="my-8 px-4 lg:px-40 bg-[#F4F6F9]">
    <app-single-store-footer
      [storeName]="storeResponse"
    ></app-single-store-footer>
  </section>
</section>
<!-- PRODUCT LIST COMPONENT END -->

<!-- DISPLAYED IF STORE NOT FOUND -->
<div *ngIf="!deactivated && storeFetched && productFetched && storeNotFound">
  <div
    class="flex justify-center items-center shadow-sm bg-red-700 font-semibold text-white"
  >
    <p class="text-center py-5">
      An error occurred while fetching store information.
      <span class="block">Please, check the store url and try again.</span>
    </p>
  </div>
</div>

<!-- DISPLAYED IF STORE IS DEACTIVATED -->

